@import "utils";

/*
╔═════════════════════════════════════════════╗
║                                             ║
║             Mobile first settings           ║
║                                             ║
╚═════════════════════════════════════════════╝*/

a *:hover {
  /* Why am I forced to add this? Link includes label! */
  cursor: pointer;
}

/*
╔═════════════════════════════════════════════╗
║                                             ║
║           Tablet / Desktop settings         ║
║                                             ║
╚═════════════════════════════════════════════╝*/

@media screen and (min-width: map-get($SCREEN_WIDTH, "md")) {
} // end @media screen and (min-width: map-get($SCREEN_WIDTH, 'md'))
