@charset "UTF-8";

/*
╔═════════════════════════════════════════════╗
║               App text styles               ║
╚═════════════════════════════════════════════╝ */

$APP_RESPONSIVE_TITLE_FONTSIZES: // Desktop and large screens
  (6000px, 1, 3.1em),
  (6000px, 2, 2.25em),
  (6000px, 3, 1.9em),
  (6000px, 4, 1.6em),
  (6000px, 5, 1.25em),
  (6000px, 6, 1em),
  // "xxl" screen
  (1400px, 1, 3.1em),
  (1400px, 2, 2.25em),
  (1400px, 3, 1.7em),
  (1400px, 4, 1.5em),
  (1400px, 5, 1.25em),
  (1400px, 6, 1em),
  // "xl" screen
  (1200px, 1, 3em),
  (1200px, 2, 2.75em),
  (1200px, 3, 1.7em),
  (1200px, 4, 1.45em),
  (1200px, 5, 1.25em),
  (1200px, 6, 1em),
  // "lg" screen
  (992px, 1, 3em),
  (992px, 2, 2.75em),
  (992px, 3, 1.65em),
  (992px, 4, 1.45em),
  (992px, 5, 1.25em),
  (992px, 6, 1em),
  // "md" screen
  (768px, 1, 3em),
  (768px, 2, 2.75em),
  (768px, 3, 1.55em),
  // "sm" screen
  (576px, 1, 3em),
  (576px, 2, 2.75em),
  (576px, 3, 1.5em),
  (576px, 4, 1.375em),
  (576px, 5, 1.25em),
  (576px, 6, 1em),
  // "x-sm" screen
  (380px, 1, 2.5em),
  (380px, 2, 1.75em),
  (380px, 3, 1.37em),
  (380px, 4, 1.31em),
  (380px, 5, 1.25em),
  (380px, 6, 1em);

:root {
  --app-font-size-standard: 1em;
  --app-font-size-large: 2em;
}
