@import "../globals";

/*
  ╔═════════════════════════════════════════════╗
  ║                   App colors                ║
  ╚═════════════════════════════════════════════╝ */

$APP_COLORS: (
  "bgdefault": white,
  // background default color
  "black": #000000,
  "blacky": #111111,
  "blackgrey": #303030,
  "blue10": #afc3e3,
  "darkergrey": #454545,
  "deepgrey": #1c1c1c1c,
  "darkgrey": #7e7e7e,
  "default": black,
  // default color
  "grey": #e2e2e2,
  "white": #ffffff,
  "beige": #fff8f2,
  "grey10": #d9d9d9,
  "grey20": #b8b8b8,
);

:root {
  /* App color classes */
  @each $name, $value in $APP_COLORS {
    --app-color-#{$name}: #{$value};
  }
}
