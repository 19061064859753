@charset "UTF-8";

/*||==============================================||
  ||                                              ||
  ||   HIDE / DISPLAY RULES UPON BREAKPOINTS      ||
  ||                                              ||
  ||      BOOSTRAP RESPONSIVE BREAKPOINTS         ||
  ||                                              ||
  ||                sm : 576 px                   ||
  ||                md : 768 px                   ||
  ||                lg : 992 px                   ||
  ||                xl : 1200 px                  ||
  ||               xxl : 1400 px                  ||
  ||                                              ||
  ||             CUSTOM BREAKPOINTS               ||
  ||                                              ||
  ||               xxl : 1550 px                  ||
  ||                                              ||
  ||    INSERT YOUR CUSTOM RULES AT THE END       ||
  ||                                              ||
  ||==============================================|| */

$BREAKPOINTS: // CAUTION : Bootstrap and additional custom breakpoints
  (xs, 321px),
  // Custom
  (sm, 576px),
  // Boostrap
  (md, 768px),
  // Boostrap
  (lg, 992px),
  // Boostrap
  (xl, 1200px),
  // Boostrap
  (xxl, 1550px) // Custom
;

.px-is-hidden,
.px-hide {
  display: none !important;
}

/*|==============================================|
  |                                              |
  |         HIDE ONLY BEYOND BREAKPOINTS         |
  |                                              |
  |==============================================| */

@each $screen-label, $breakpoint in $BREAKPOINTS {
  @media screen and (min-width: #{$breakpoint}) {
    /* SCSS generated */
    .px-hide-#{$screen-label} {
      display: none !important;
    }
  }
} // end @each

/*|==============================================|
  |                                              |
  |      DISPLAY ONLY BEYOND BREAKPOINTS         |
  |                                              |
  |==============================================| */

@each $screen-label, $breakpoint in $BREAKPOINTS {
  @media screen and (max-width: #{$breakpoint - 1}) {
    .px-display-#{$screen-label} {
      /* SCSS generated */
      display: none !important;
    }
  }
} // end @each

/*|==============================================|
  |                                              |
  |              NO DISPLAY INTERVALS            |
  |                                              |
  |==============================================| */

@each $label, $breakpoint in $BREAKPOINTS {
  @each $label2, $breakpoint2 in $BREAKPOINTS {
    @if $breakpoint < $breakpoint2 {
      @media screen and (max-width: $breakpoint - 1),
        (min-width: $breakpoint2) {
        .px-display-#{$label}-to-#{$label2} {
          /* SCSS generated */
          display: none !important;
        }
      }
    }
  }
}

/*|==============================================|
  |                                              |
  |            OPACITY / TRANSPARENCY            |
  |                                              |
  |==============================================| */

@for $i from 1 through 20 {
  $opacity: $i * 5;
  $transparency: 100 - $opacity;
  .px-opacity-#{$opacity} {
    opacity: #{$opacity};
  }
  .px-transparency-#{$transparency} {
    opacity: 100-$opacity;
  }
}
