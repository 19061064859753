@import "utils";
// @import "modal-box";

/*
╔═════════════════════════════════════════════╗
║                                             ║
║             Mobile first settings           ║
║                                             ║
╚═════════════════════════════════════════════╝*/

.app-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-height: 150px;
  font-family: var(--app-font-sf-prodisplay-medium);
  font-size: 1em;
  padding: 0.3em 0 0.3em 1em;
  border-bottom: solid 2px var(--app-color-grey);
  background-color: var(--app-color-white);
  position: sticky;
  top: 0;
  z-index: 10;
  /** important pour surlignage item courant **/
  overflow: hidden;
  @include uppercase;

  .accessibility-settings {
    @include piled-and-centered;
    @include uppercase;
    padding-right: 1em;
  }

  // end .app-accessibility
  .app-name {
    align-self: unset;
    text-transform: capitalize;

    svg {
      height: 25px;
    }
  }

  // end .app-name
  .navbar-items {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: small;

    label {
      font-family: var(--app-font-sf-prodisplay-medium);
      color: var(--app-color-black);
    }

    .navbar-item {
      border-right: solid 0.1875em var(--app-color-grey);
      text-align: center;
      /* regular (i.e. non static) navbar items */
      margin-top: 0.5em;
      padding-right: 1.5em;
      padding-left: 1.5em;
      margin-bottom: 1em;
      border-right: solid 0.1875em var(--app-color-grey);
      @include hover-hand;

      &:first-of-type {
        flex-direction: column;
      }
      .navbar-item-label {
        cursor: pointer;
      }
    }

    .static {
      position: relative;

      &:hover::before {
        position: absolute;
        left: 0;
        top: -20px;
        z-index: -1;
        border: red 2px dotted;
        height: 150px; // mandatory to create a background area
        width: 100%;
      }
    }
  }

  // end .navbar-items
  .mobile-menu {
    display: flex;
    flex-direction: column;
    background-color: var(--app-color-white);
    border-bottom: ridge 2px var(--app-color-grey);
    width: 105%;
    padding: 1em;
    position: fixed;
    left: 0;
    top: -100%;
    line-height: 1.25em;
    z-index: 10;

    &[is_active="true"] {
      top: 11ex;
      // top: 0;
    }

    .navbar-item {
      padding: 0.25em 2em;
      font-family: var(--app-font-sf-prodisplay-regular);
      font-family: var(--app-font-sf-prodisplay-bold);

      @include hover-hand;

      &:hover {
        transition: text-decoration-line 3s;
        text-decoration-color: var(--app-color-black);
        text-decoration-line: underline;
        text-decoration-thickness: 15%;
      }
    }

    .navbar-item-icon {
      display: none;
    }
  }

  // end .mobile-menu
  .mobile-menu-toggler {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: small;

    svg {
      width: 10px;
    }

    @include hover-hand;
  }

  // end .mobile-menu-toggler
  .navbar-item {
    /* menu item vertical layout (icon + label) */
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    font-size: 1em;

    .navbar-item-icon {
      height: 30px;
      margin-bottom: 0;
      padding-top: 1em;

      svg {
        height: 100%;
      }
    }
  }

  // end .navbar-item
}

// end app-menu

/*
╔═════════════════════════════════════════════╗
║                                             ║
║           Tablet / Desktop settings         ║
║                                             ║
╚═════════════════════════════════════════════╝*/

@media screen and (min-width: map-get($SCREEN_WIDTH, "md")) {
  .app-menu {
    padding: 1em 0 1em 1em;
    .desktop-menu {
      display: flex;
      width: 100%;
      font-size: 0.8em;

      .navbar-item {
        position: relative;
        padding: 0.25em 2em;
        border: unset;
        border-right: solid 0.1875em var(--app-color-grey);
        text-align: center;

        &:hover::before {
          content: "";
          position: absolute;
          left: 0;
          top: -20px;
          height: 150px; // mandatory to create a background area
          z-index: -1;
          background-color: var(--app-color-beige);
          width: 100%;
        }

        &:hover:first-of-type {
          border-left: 1px solid var(--app-color-grey);
        }

        /* &:hover:first-of-type:after {
          content: "";
          height: 100%;
          width: .1875em;
          position: absolute;
          left: 0;
          top: 0;
          background-color: var(--app-color-grey);
        } */
        &:last-of-type {
          border-right: unset;
        }

        .navbar-item-icon {
          // height:20px;
          margin-bottom: 0.5ex;

          svg {
            height: 100%;
          }
        }

        .navbar-item-label {
          min-width: max-content;
        }
      }

      [aria-current="true"] {
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: -20px;
          z-index: -1;
          background-color: var(--app-color-beige);
          height: 150px;
          width: 100%;
        }

        &:first-of-type:after {
          content: "";
          height: 100%;
          width: 0.1875em;
          position: absolute;
          left: 0;
          top: 0;
          background-color: var(--app-color-grey);
        }
      }
    }

    .navbar-item {
      @include hover-hand;
      padding: 0.25em 2em;
    }

    .navbar-item-icon {
      margin-bottom: 2ex;
    }
  }

  // end .app-menu
}

// end @media screen and (min-width: map-get($SCREEN_WIDTH, 'lg'))
