/*
╔═════════════════════════════════════════════╗
║           Set of heights and widths         ║
╚═════════════════════════════════════════════╝ */

@each $dim-px in $DIMENSIONS_PX {
  .app-h-#{$dim-px} {
    height: $dim-px;
  }
  .app-w-#{$dim-px} {
    width: $dim-px;
  }
}

@each $dim in $DIMENSIONS_PERCENT {
  .app-h-#{$dim} {
    height: unquote($dim + '%');
  }
  .app-w-#{$dim} {
    width: unquote($dim + '%');
  }
  @each $screen-prefix, $breakpoint in $BS-BREAKPOINTS-ALIASES {
    @media screen and (min-width: #{$breakpoint - 1}) {
      .app-h-#{$screen-prefix}-#{$dim} {
        height: unquote($dim + '%');
      }
      .app-w-#{$screen-prefix}-#{$dim} {
        width: unquote($dim + '%');
      }
      .app-w-#{$screen-prefix}-third {
        flex:0 0 auto;
        width: 33.333333%;
      }
    }
  }
}

/*
╔═════════════════════════════════════════════╗
║              Set of line heights            ║
╚═════════════════════════════════════════════╝ */

@each $label, $height in $APP_LINE_HEIGHTS {
  :root {
    --app-lineheight-#{$label}: #{$height};
  }
  .app-lineheight-#{$label} {
    line-height: $height;
  }
}

