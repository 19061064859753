/*
╔═════════════════════════════════════════════╗
║            Style settings: footer           ║
╚═════════════════════════════════════════════╝*/

@import "utils";

/*
╔═════════════════════════════════════════════╗
║                                             ║
║             Mobile first settings           ║
║                                             ║
╚═════════════════════════════════════════════╝*/

.footer {
  color: #6c757d;
  padding: 20px 0;
  text-align: center;
  font-family: "Arial", sans-serif;

  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-links {
    margin: 0;
    font-size: 14px;

    .text-muted {
      color: #b0b0b0;
    }

    a {
      color: #000;
      text-decoration: none;
      transition: color 0.3s;

      &:hover {
        color: #555;
      }
    }
  }

  .legal-link {
    margin-top: 10px;
    font-size: 12px;

    a {
      color: #b0b0b0;
      text-decoration: none;

      &:hover {
        color: #888;
      }
    }
  }
}

/*
╔═════════════════════════════════════════════╗
║                                             ║
║           Tablet / Desktop settings         ║
║                                             ║
╚═════════════════════════════════════════════╝*/

@media screen and (min-width: map-get($SCREEN_WIDTH, "md")) {
  footer {
  } // end footer
} // end @media screen and (min-width: map-get($SCREEN_WIDTH, 'md'))
