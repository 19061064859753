@import "utils";

/*
╔═════════════════════════════════════════════╗
║                                             ║
║             Mobile first settings           ║
║                                             ║
╚═════════════════════════════════════════════╝*/

.information-panel {
  background-color: var(--app-color-bgdefault);

  margin-bottom: 1em;

  .panel-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.75em 0.5em;
  } // end .panel-content
  .panel-header {
    .panel-title {
      text-align: center;
    } // end .panel-title
  } // end .panel-header
  .information-card {
    margin: 2px !important;
    .card-block {
      position: relative;
      .read-more {
        position: absolute;
        bottom: 10px;
      }
    }
  }
} // end .information-panel

#information-pannel-ou-emploi {
  background: rgb(45, 45, 45);
  margin: 1.5em 0;
  width: 100%;
  .app-title {
    color: white;
    margin-top: 1em;
  }
}

#information-pannel-accompnement {
  .panel-content {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
    margin: 1px 4px;
    .information-card {
      margin: 2px !important;
      width: 50%;
      .description {
        text-overflow: ellipsis;
        line-clamp: 6;
        -webkit-line-clamp: 6;
        max-height: calc(6 * 1.2em);
        line-height: 1.2em;
      }
      .picture-box {
        display: flex;
        justify-content: center;
        img {
          object-fit: contain;
          width: unset;
          max-width: 100%; /* Ne dépasse jamais la largeur de son conteneur */
          height: auto;
        }
      }
    }
  }
}

/*
╔═════════════════════════════════════════════╗
║                                             ║
║         Intermediate screen settings        ║
║                                             ║
╚═════════════════════════════════════════════╝*/

@media screen and (min-width: map-get($SCREEN_WIDTH, "sm")) and (max-width: map-get($SCREEN_WIDTH, "md")) {
  .information-panel {
    margin: 0;
    padding: 0;
    .panel-content {
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      align-items: flex-start;
      gap: 10px 10px;
    } // end .panel-content
    .panel-header {
      margin: 0;
    }
  }
} // end @media screen and (min-width: map-get($SCREEN_WIDTH, 'md'))

/*
╔═════════════════════════════════════════════╗
║                                             ║
║           Tablet / Desktop settings         ║
║                                             ║
╚═════════════════════════════════════════════╝*/

@media screen and (min-width: map-get($SCREEN_WIDTH, "md")) {
  .information-panel {
    padding: 0;
    margin: 1.5em 10%;
    .panel-content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px 10px;
      padding: 0;
    }
    .panel-header {
      margin: 1.5em 0;
    }
  } // end .information-panel

  #information-pannel-ou-emploi {
    .app-title {
      padding-left: 10%;
    }
  }

  #information-pannel-postuler {
    background-color: #fff8f2;
    width: 100%;
    padding: 3% 10%;
    margin-left: 0;
    margin-bottom: 0;
    border-top: 1 solid #797676;
  }
} // end @media screen and (min-width: map-get($SCREEN_WIDTH, 'md'))
