@import "utils";

.container_loader {
  display: block;
  height: 30vh;
  margin-top: 40%;
  position: relative;
  z-index: 1;
}

.container_loader > p {
  display: block;
  text-align: center;
  margin: 10vh;
  font-weight: 500;
  font-size: 2em;
}

.loader {
  width: 8px;
  height: 40px;
  border-radius: 4px;
  display: block;
  margin: 20px auto;
  position: relative;
  background: currentColor;
  color: #000;
  box-sizing: border-box;
  animation: animloader 0.8s 0.6s linear infinite alternate;
  max-height: 1em;
}

.loader::after,
.loader::before {
  content: "";
  width: 8px;
  height: 40px;
  border-radius: 4px;
  background: currentColor;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  box-sizing: border-box;
  animation: animloader 0.6s 0.45s linear infinite alternate;
}

.loader::before {
  left: -20px;
  animation-delay: 0s;
}

@keyframes animloader {
  0% {
    height: 48px;
  }

  100% {
    height: 4px;
  }
}
