@charset "UTF-8";

@import "utils";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  color: var(--app-color-default);
}

a,
a:visited,
a:hover {
  text-decoration: none;
}

body {
  font-family: var(--app-font-sf-prodisplay-regular);
  height: 100%;
  max-width: $APP_MAX_CONTENT_WIDTH;
  margin: 0 auto;
  overflow-x: hidden;
}

.app {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.app-letter {
  max-height: 100vh;
  overflow: hidden;
  display: block !important;
}

.fullpage {
  // left here by Pr. Chaos
  flex-grow: 1;
}

/* Class 'hide' below added by Pr. Chaos but seems unused.
  If you need to hide elements take a look at Code Phenix's '_px' directory.
  You'll find '_visibility' partial amond others.
*/
.hide {
  // display: none;
}

main {
  margin: 0 auto;
  max-width: $APP_MAX_CONTENT_WIDTH;
  position: relative;
  width: 90%;
}

/*

/* --------- mixins END------------ */

button {
  padding: 0.75em;
  @include curved-corners;
  @include hover-hand;
  border: none;
  gap: 0.25em;

  .fleche {
    height: 10px;
  }
}

.bottom-link {
  a {
    gap: 0.25em;
  }

  .fleche {
    height: 10px;
  }
}

/*
╔═════════════════════════════════════════════╗
║                                             ║
║           Tablet / Desktop settings         ║
║                                             ║
╚═════════════════════════════════════════════╝*/

@media screen and (min-width: map-get($SCREEN_WIDTH, "md")) {
  button {
    padding: 1em;
  }
  .app-letter {
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
}

// end @media screen and (min-width: $BREAKPOINT-DESKTOP)
