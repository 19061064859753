@import "utils";

/*
╔═════════════════════════════════════════════╗
║                                             ║
║             Mobile first settings           ║
║                                             ║
╚═════════════════════════════════════════════╝*/

/*  Default settings. To be overriden in some parent component style. */

.information-card {
  display: flex;
  // display: grid; grid: auto-flow / [line1] minmax(10em, max-content);  grid: repeat(3, 1fr);
  flex-direction: column;
  height: 400px;
  background-color: var(--app-color-white);
  color: var(--app-color-darkergrey);
  border: solid 1px var(--app-color-grey10);
  border-radius: 8px;
  max-width: 18em;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.75);
  overflow-y: scroll;
  .picture-box {
    max-height: 110px;
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
  }
  .card-block {
    display: flex;
    flex-direction: column;
    padding: 1em 0.5em;
    padding-bottom: 0;
    height: 100%; // mandatory to draw read-more section to the bottom!
    .description {
      font-family: "Georgia";
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      text-align: left;
      margin: 0.5em 0;
      flex-grow: 1;
      text-align: left;
      overflow: cut;
      text-overflow: ellipsis;
      white-space: pre-wrap;
    }
    .read-more {
      font-weight: bold;
      a {
        display: flex;
        .widget {
          position: relative;
          width: 14px;
          margin-left: 8px;
          svg {
            position: absolute;
            top: 5px;
          }
        }
      }
    } // end .read-more
    .subject {
      text-align: left;
    }
  } // .end .card-block
} // end .information-card

/*
╔═════════════════════════════════════════════╗
║                                             ║
║         Intermediate screens settings       ║
║                                             ║
╚═════════════════════════════════════════════╝*/

@media screen and (min-width: map-get($SCREEN_WIDTH, "sm")) {
  .information-card {
    .picture-box {
      width: 100%;
      height: 154px;
      min-height: 154px;
      margin-top: 0.5em;
      img {
        object-fit: cover;
        max-height: 100%;
      }
    }
  }
} // end @media screen and (min-width: $BREAKPOINT-DESKTOP)

/*
╔═════════════════════════════════════════════╗
║                                             ║
║           Tablet / Desktop settings         ║
║                                             ║
╚═════════════════════════════════════════════╝*/

@media screen and (min-width: map-get($SCREEN_WIDTH, "md")) {
  .information-card {
    .picture-box {
      margin-top: 0.5em;
    }
    // min-width: 13em;
  }
}
