@import "utils";

/*
╔═════════════════════════════════════════════╗
║                                             ║
║             Mobile first settings           ║
║                                             ║
╚═════════════════════════════════════════════╝*/

.landing-page {
  display: flex;
  flex-direction: column;
  #typewriter-zone {
    align-self: center;
    text-align: center;
    font-size: var(--app-font-size-title3);
  }
  .bottom-link {
    a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      min-width: 150px;
      max-width: 150px;
      height: 6ex;
      // border: .015em solid var(--app-color-darkgrey);
      line-height: 0;
      // background-color: var(--app-color-white);
      span {
        color: var(--app-color-blackgrey);
        font-weight: bold;
      }
      // svg {
      //   height: 10px;
      // }
      &:hover {
        text-decoration: underline;
        // box-shadow: 0 0.25em var(--app-color-grey);
        // background-color: var(--app-color-black);
        // border-color: var(--app-color-black);
        span {
          // color: var(--app-color-white);
        }
        svg path {
          // fill: var(--app-color-white)!important;
        }
      }
    }
  }
} // end .landing-page

/*
╔═════════════════════════════════════════════╗
║                                             ║
║           Tablet / Desktop settings         ║
║                                             ║
╚═════════════════════════════════════════════╝*/

@media screen and (min-width: map-get($SCREEN_WIDTH, "md")) {
} // end @media screen and (min-width: map-get($SCREEN_WIDTH, 'md'))
