@import "utils";

/*
╔═════════════════════════════════════════════╗
║                                             ║
║             Mobile first settings           ║
║                                             ║
╚═════════════════════════════════════════════╝*/

/* local variables */
$FORM_INPUT_MAX_WIDTH: 650px;
$FORM_ELEMENTS_HEIGHT: 36px;

.questionnaire {
  background-color: var(--app-color-beige);
  width: 100%;
  //min-height: 100vh;
  flex-grow: 1;
  // display: flex;
  flex-direction: column;
  .experience-questionnaire {
    flex-grow: 1;
    background-color: var(--app-color-beige);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    // margin: 0 auto;
    /* & dialog {
      margin: auto auto;
      width: 80%;
      padding: 1em;
      &::backdrop {
        filter: opacity(0.5);
        background-color: black;
      }
    }  */ // end dialog
    .questionnaire-body {
      width: 100%;
      margin: 0 10%;
      /* min-height: 20em;
      max-width: 45em; */
      // display: flex;
      flex-direction: column;
      // padding: 5em 1em 1em 1em;
      padding: 1em;
      flex-grow: 1;
      fieldset.choices {
        // user answers (through radio buttons, text areas) to current question
        // display: flex;
        flex-direction: column;
        flex-grow: 1;
        text-align: center;
        border: none;
        padding: 0;
        margin: 1em 0;
        .choice-container {
          position: absolute;
          bottom: 3em;
          right: 0;
          padding: 1em;
          width: 100%;
        }
        .question-title {
          margin: 0 auto;
          // font-size: 1.5em;
          font-size: 25px;
          line-height: 25px;
          font-style: normal;
          font-weight: 400;

          // margin-bottom: 2em;
        } // end .question-title
      } // end fieldset.choices
      .help-widget {
        position: relative;
        width: 100%;
        height: 2.5em;
        margin: 0em 0 1em;
        transform: scale(0.75);
        z-index: 10;
        @include hover-hand;
        .help-button {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: 0 auto;
          width: 6em;
        } // end .help-button
      } // end .help-widget
      input[type="checkbox"] {
        display: none; // hide checkbox (and let it be replaced by its label)
        & + label {
          @include hover-hand;
          display: flex;
          justify-content: center;
          align-items: center;
          // min-height: 5ex;
          min-height: $FORM_ELEMENTS_HEIGHT;
          padding: 0.5em;
          max-width: $FORM_INPUT_MAX_WIDTH;
          font-size: 1em;
          background-color: var(--app-color-white);
          margin: 0.5em auto;
          border: 0.0625em solid var(--app-color-darkgrey);
          // border-radius: 0.75em;
          @include curved-corners;
          // box-shadow: 0.1em 0.1em var(--app-color-grey);
          box-shadow: 0px 3px var(--app-color-grey);
          & > span {
            margin: auto 0;
            line-height: 1;
            font-family: var(--app-font-georgia);
            font-size: 20px;
          }
          & > span.loader {
            transform: scale(0.5);
          }
        }
        & + label.question-ten {
          font-size: 0.8em;
        }
        &[userchecked] + label {
          border-width: 0.15em;
          border-color: var(--app-color-blackgrey);
          box-shadow: 0 2px var(--app-color-darkgrey);
        }
      } // end input[type=checkbox]
      .profile {
        margin: 2.5em 0;
        text-align: justify;
        p {
          margin-bottom: 0.5em;
        }
      } // end .profile
      #question-number {
        text-align: center;
        @include uppercase;
        margin-bottom: 1em;
        font-family: var(--app-font-sf-prodisplay-bold);
        font-size: small;
      } // end #question-number
      .textarea-container {
        position: relative;
        margin: 0 auto;
        padding: 1em 0;
        // height: 350px;
        .closing-widget {
          position: absolute;
          height: 20px;
          opacity: 0.8;
          top: 1em;
          right: -0em;
          background-color: var(--app-color-white);
          clip-path: circle();
          border: 2px solid var(--app-color-black);
          border-radius: 50%;
          svg {
            height: 100%;
          }
          &:hover {
            opacity: 1;
            background-color: var(--app-color-black);
            > svg {
              filter: invert(1);
            }
          }
        } // end .closing-widget
        textarea {
          width: 100%;
          height: 25vh;
          resize: none;
          overflow: hidden;
          display: block;
          padding: 0.6em;
          box-sizing: border-box;
          border-style: solid;
          border-width: 0 1px 3px 1px;
          @include curved-corners;
          outline: unset;
          font-size: larger;
          &:focus-visible {
          }
        } // end textarea
      } // end .textarea-container
      .control-widgets {
        position: fixed;
        bottom: 1em;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        // gap: 1em;
        // margin: 1.5em 0 1em 0;
        .control-widgets-content {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          max-width: $FORM_INPUT_MAX_WIDTH;
          margin: 0px 1em;
          gap: 1em;
        }

        &.wrapme {
          flex-wrap: wrap;
        }
        & a {
          display: block;
          width: 100%;
        }
        button {
          width: 100%;
          // height: 5.5ex;
          height: $FORM_ELEMENTS_HEIGHT;
          color: var(--app-color-white);
          border-width: 0.15em;
          border-style: solid;
          border-radius: 0.75em;
          padding: 0.8em;
          line-height: 0;
          border-color: var(--app-color-darkgrey);
          background-color: var(--app-color-black);
          display: inline-flex;
          gap: 0.3em;
          align-items: center;
          justify-content: center;
          svg {
            height: 65%;
          }
          & span {
            color: inherit;
            line-height: 0;
          }
          &[disabled] {
            color: var(--app-color-grey20);
            background-color: var(--app-color-beige);
            border-color: var(--app-color-grey20);
            svg path {
              fill: var(--app-color-grey20) !important;
            }
          }
          &:not([disabled]):hover {
            box-shadow: 0 0.25em var(--app-color-grey);
            color: var(--app-color-white);
            background-color: var(--app-color-black);
            border-color: var(--app-color-black);
            svg path {
              fill: var(--app-color-white) !important;
            }
          }
        } // button
      } // end .control-widget
    } // end .questionnaire-body
  } // end .experience-questionnaire
  .questionnaire-header {
    background-color: var(--app-color-white);
  } // end .questionnaire-header
} // end .questionnaire

.panelShow {
  // position: relative;
  // z-index: +111;
  // bottom: -35vh;
  // left: -12vh;
  // margin: auto;
  bottom: 1vh;
  right: 1vh;
  position: fixed;
  z-index: +1000;
}

/*
╔═════════════════════════════════════════════╗
║                                             ║
║               Custom settings               ║
║                                             ║
╚═════════════════════════════════════════════╝*/

@media screen and (min-width: map-get($SCREEN_WIDTH, "xs")) {
  .questionnaire-body {
    .question-title {
      margin: 0 auto;
      font-size: 1.7em;
      // margin-bottom: 2em;
      @include linebreak;
    } // end .question-title
    .textarea-container {
      // height: 350px;
      height: auto;
    } // end .textarea-container
  } // end .questionnaire-body
} // end @media screen and (min-width: map-get($SCREEN_WIDTH, 'xs')

/*
╔═════════════════════════════════════════════╗
║                                             ║
║           Tablet / Desktop settings         ║
║                                             ║
╚═════════════════════════════════════════════╝*/

@media screen and (min-width: map-get($SCREEN_WIDTH, "sm")) {
  .questionnaire {
    .experience-questionnaire {
      .questionnaire-body {
        // margin-top: 3em;
        margin-top: 1em;
        fieldset.choices {
          .question-title {
            // question title
            margin-bottom: 0 !important;
            width: 480px;
            font-size: 2em;
          } // end .question-title
          .choice-container {
            position: static;
          } // end choice-container
          .textarea-container {
            textarea {
              margin-top: 0em;
              font-size: x-large;
              height: auto;
            } // end textarea
          } // end .textarea-container
          input[type="checkbox"] {
            & + label,
            & + label.question-ten {
              font-size: 1.2em;
            }
          }
        } // end fieldset.choices
      } // end .questionnaire-body
    } // . end experience-questionnaire
  } // end .questionnaire
} // end @media screen and (min-width: map-get($SCREEN_WIDTH, 'sm'))

@media screen and (min-width: map-get($SCREEN_WIDTH, "md")) {
  .questionnaire {
    .questionnaire-header {
      height: 3em;
      .breadcrumbs {
        .stage-tab-button {
          font-size: 0.6em;
        }
      }
    }
    .experience-questionnaire {
      //   width: 80%;
      margin-top: 3em;
      .questionnaire-body {
        // margin-top: 4em;
        fieldset.choices {
          .choice-container {
            position: static;
            bottom: 3em;
            right: 0;
            padding: 1em;
            width: 100%;
          }
          .question-title {
            // question title
            width: 720px;
            font-size: 2em;
            line-height: 44px;
            font-size: 32px;
            @include linebreak;
          } // end .question-title
          .textarea-container {
            max-width: $FORM_INPUT_MAX_WIDTH;
            margin-top: 0;
            textarea {
              @include center-horizontally;
            } // end textarea
          } // end .textarea-container
          input[type="checkbox"] {
            margin: 1.5em auto;
            & + label {
              margin: 1em auto;
            }
          }
        } // end fieldset.choices
        .control-widgets {
          bottom: 1em;
        }
      } // end .questionnaire-body
    } // . end experience-questionnaire
  } // end .questionnaire
} // end @media screen and (min-width: map-get($SCREEN_WIDTH, 'md'))

/*
╔═════════════════════════════════════════════╗
║                                             ║
║           Tablet / Desktop settings         ║
║                                             ║
╚═════════════════════════════════════════════╝*/

@media screen and (max-height: 800px) and (min-width: map-get($SCREEN_WIDTH, "md")) {
  .app-menu {
    padding: 0 1em;
  }
  .questionnaire {
    .questionnaire-header {
      height: 3em;
      .breadcrumbs {
        .stage-tab-button {
          font-size: 0.6em;
        }
      }
    }
    .experience-questionnaire {
      margin-top: 0;
      .questionnaire-body {
        .help-widget {
          margin: 0;
        }
        fieldset.choices {
          .textarea-container {
            textarea {
              height: 30vh;
            }
          }
        }
      }
    }
  }
}
