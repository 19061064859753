.indication-magnet {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 2ex;
  width: fit-content;
  text-align: left;
  //height: 6ex;
  // top: var(--top);
  color: var(--app-color-white);
  background-color: var(--app-color-darkergrey);
  padding: 2ex 2em;
  z-index: 10;
  top: 2em;
  left: 0;
  right: 0;
  margin: 0 auto;

  p {
    color: var(--app-color-white);
  }
  // &.arrow-up::before {
  //   content: "";
  //   width: 100%;
  //   height: 100%;
  //   max-height: 6ex;
  //   position: absolute;
  //   left: 0;
  //   right: 0;
  //   bottom: 100%;
  //   background-color: var(--app-color-darkergrey);
  //   clip-path: polygon(0 100%, 100% .9em, 100% 100%);
  // }

  &.arrow-down::before {
    content: "";
    width: 100%;
    height: 100%;
    max-height: 6ex;
    position: absolute;
    left: 0;
    right: 0;
    top: 99%;
    background-color: var(--app-color-darkergrey);
    clip-path: polygon(45% 0px, 50% 60%, 55% 0px);
  }
}