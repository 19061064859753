@import "utils";

/*
╔═════════════════════════════════════════════╗
║                                             ║
║             Mobile first settings           ║
║                                             ║
╚═════════════════════════════════════════════╝*/

.fullpage {
  display: flex;
  flex-direction: column;
  margin: 1em 1.5em;

  h1 {
    margin: 0.75em 0;
  }
  .legal-section {
    margin-bottom: 1.25em;
    h2 {
      margin-bottom: 0.5em;
    }
  }
}

/*
╔═════════════════════════════════════════════╗
║                                             ║
║           Tablet / Desktop settings         ║
║                                             ║
╚═════════════════════════════════════════════╝*/

@media screen and (min-width: map-get($SCREEN_WIDTH, "md")) {
} // end @media screen and (min-width: map-get($SCREEN_WIDTH, 'md'))
