@import "utils";

.asw-container {
  .asw-widget {
    display: none;
  }
  div:nth-of-type(2) {
    .asw-menu {
      right: 0 !important;
      left: auto !important;
      .asw-menu-header {
        background-color: var(--app-color-black) !important;
          svg{
            fill: var(--app-color-black) !important;
          }
      }
      .asw-footer a {
        display: none !important;
      }
    }
  }
}

// A ajouter quelque part pour appliquer la CSS
