@import "utils";

// Design by Pr Chaos, spoiled by Cody.

$BUTTON_HEIGHT: 3;
$SHADOW_THICKNESS: 0.2;

/*
╔═════════════════════════════════════════════╗
║                                             ║
║            Mobile first settings            ║
║                                             ║
╚═════════════════════════════════════════════╝*/

.questionnaire-header {
  //display: flex;
  //flex-direction: row;
  overflow-x: auto;
  //align-items: center;
  //justify-content: center;
  font-size: 1.3em;
  display: flex;
  //justify-content: center;
  .breadcrumbs {
    display: inline-flex;
    align-items: center;
    // justify-content: left;
    // flex-wrap: wrap;
    padding: 0.5em;
    .stage-tab-button {
      font-size: 0.9em;
      height: $BUTTON_HEIGHT + em;
      padding: 0 1em;
      margin-top: -0.2em;
      margin-right: 0.5em;
      border-radius: ($BUTTON_HEIGHT / 2) + em;
      border-width: 1px;
      border-style: solid;
      border-color: transparent;
      white-space: nowrap;
      &::selection {
        color: inherit;
        background: inherit;
      }
      /* &:not(:first-child) {
        margin-left: 0.5em;
      } */
      &[state="waiting"] {
        margin-top: calc($SHADOW_THICKNESS / 2) + em;
        background-color: var(--app-color-white);
        border-style: dashed;
        border-color: var(--app-color-grey20);
        color: var(--app-color-grey20);
        cursor: default;
        //box-shadow: 0 ($SHADOW_THICKNESS + em) map-get($APP_COLORS, "white");
      }
      &[state="done"] {
        background-color: var(--app-color-grey);
        color: var(--app-color-darkergrey);
        box-shadow: 0 ($SHADOW_THICKNESS + em) var(--app-color-grey20);
      }
      &[state="done"][hoverable]:hover {
        border-style: solid;
        border-color: var(--app-color-black);
      }
      &[state="done"]:not([hoverable]):hover {
        cursor: default;
      }
      &[state="active"] {
        background-color: var(--app-color-blackgrey);
        color: var(--app-color-white);
        box-shadow: 0 ($SHADOW_THICKNESS + em) var(--app-color-grey);
        cursor: default;
      }
    }
  } // end .breadcrumbs
} // end .questionnaire-header

/*
╔═════════════════════════════════════════════╗
║                                             ║
║          Tablet / Desktop settings          ║
║                                             ║
╚═════════════════════════════════════════════╝*/

@media screen and (min-width: map-get($SCREEN_WIDTH, "md")) {
  .questionnaire-header {
    height: $BUTTON_HEIGHT + 2 + em;
    flex-wrap: nowrap;
    .breadcrumbs {
      margin: 1em auto;
      display: inline-flex;
      .stage-tab-button {
        // height: $BUTTON_HEIGHT + em;
        &[state="waiting"] {
          height: $BUTTON_HEIGHT + $SHADOW_THICKNESS + em;
        }
      } // end .stage-tab-button
    } // end .breadcrumbs
  } // end .questionnaire-header
} // end @media screen and (min-width: map-get($SCREEN_WIDTH, 'md')
