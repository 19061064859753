@import "utils";

/*
╔═════════════════════════════════════════════╗
║                                             ║
║             Mobile first settings           ║
║                                             ║
╚═════════════════════════════════════════════╝*/

.picture-box {
  img,
  svg {
    width: 100%;
    object-fit: cover;
    object-position: 0%;
  }
} // end .picture-box

/*
╔═════════════════════════════════════════════╗
║                                             ║
║        Intermediate screen settings         ║
║                                             ║
╚═════════════════════════════════════════════╝*/

@media screen and (min-width: map-get($SCREEN_WIDTH, "sm")) {
  .picture-box {
    img,
    svg {
    }
  } // end .picture-box
} // end @media screen and (min-width: map-get($SCREEN_WIDTH, 'sm'))

/*
╔═════════════════════════════════════════════╗
║                                             ║
║           Tablet / Desktop settings         ║
║                                             ║
╚═════════════════════════════════════════════╝*/

@media screen and (min-width: map-get($SCREEN_WIDTH, "md")) {
} // end @media screen and (min-width: map-get($SCREEN_WIDTH, 'md'))
