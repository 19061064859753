/*
╔═════════════════════════════════════════════╗
║            Style settings: footer           ║
╚═════════════════════════════════════════════╝*/

@import "utils";

/*
╔═════════════════════════════════════════════╗
║                                             ║
║             Mobile first settings           ║
║                                             ║
╚═════════════════════════════════════════════╝*/

.frc-captcha {
  margin-bottom: 1em;
} // end footer

/*
╔═════════════════════════════════════════════╗
║                                             ║
║           Tablet / Desktop settings         ║
║                                             ║
╚═════════════════════════════════════════════╝*/

@media screen and (min-width: map-get($SCREEN_WIDTH, "md")) {
} // end @media screen and (min-width: map-get($SCREEN_WIDTH, 'md'))
