@import "utils";

/*
╔═════════════════════════════════════════════╗
║                                             ║
║             Mobile first settings           ║
║                                             ║
╚═════════════════════════════════════════════╝*/

$x-offset-absolute: 1%;

.content {
  // position: relative;
  // margin-top: 2em;
}

.closing-widget {
  display: flex;
  flex-direction: row-reverse;
}
/*
  I couldn't manage to darken the background as the modal box slides in.
  Using document.body or any other node does not work.
*/
.darkening {
  filter: brightness(0.2);
}

.darken {
  webkit-animation-name: darken;
  animation-name: darken;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes darken {
  0% {
    -webkite-filter: brightness(1);
    filter: brightness(1);
    -webkit-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkite-filter: brightness(0.2);
    filter: brightness(0.2);
    -webkit-opacity: 0.2;
    opacity: 0.2;
  }
} // end darken animation

@-webkite-keyframes darken {
  0% {
    -webkite-filter: brightness(1);
    filter: brightness(1);
  }
  100% {
    -webkite-filter: brightness(0.2);
    filter: brightness(0.2);
  }
} // end darken animation

.modal-box {
  position: fixed;
  top: 10px;
  right: 0;
  width: 95vw;
  // height: discarded-here-because-computed;
  background-color: var(--app-color-white);
  border: solid var(--app-color-grey10) 1px;
  padding: 0.5em;
  border-radius: 8px;
  z-index: 10;
  &:hover {
    cursor: default;
  }
  .closing-widget {
    position: absolute;
    right: 0;
    top: 0px;
    width: 2.5em;
  }
}

.slide-in-right {
  -webkit-animation-name: slide-in-right;
  animation-name: slide-in-right;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
} // end class slide-in-right

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateX(-$x-offset-absolute);
    transform: translateX(-$x-offset-absolute);
  }
} // end @-webkite-keyframes slide-in-right

@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateX(-$x-offset-absolute);
    transform: translateX(-$x-offset-absolute);
  }
} // end @keyframes slide-in-right

.slide-out-right {
  -webkit-animation-name: slide-out-right;
  animation-name: slide-out-right;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
} // end .slide-out-right

@-webkit-keyframes slide-out-right {
  0% {
    -webkit-transform: translateX(-$x-offset-absolute);
    transform: translateX(-$x-offset-absolute);
  }
  100% {
    -webkit-transform: translateX(200%);
    transform: translateX(200%);
    visibility: visible;
  }
} // end @-webkit-keyframes slide-out-right

@keyframes slide-out-right {
  0% {
    -webkit-transform: translateX($x-offset-absolute);
    transform: translateX($x-offset-absolute);
    visibility: hidden;
  }
  100% {
    -webkit-transform: translateX(200%);
    transform: translateX(200%);
    visibility: visible;
  }
} // end @keyframes slide-out-right

@media screen and (min-width: map-get($SCREEN_WIDTH, "sm")) {
  .modal-box {
    padding: 0.5em;
    width: 75vw;
  }
}

/*
╔═════════════════════════════════════════════╗
║                                             ║
║           Tablet / Desktop settings         ║
║                                             ║
╚═════════════════════════════════════════════╝*/

@media screen and (min-width: map-get($SCREEN_WIDTH, "md")) {
  .modal-box {
    padding: 1em;
    width: 50vw;
    .closing-widget {
      @include hover-hand;
    }
  }
}

@media screen and (min-width: map-get($SCREEN_WIDTH, "lg")) {
  .modal-box {
    width: 40vw;
    right: 25px;
    padding: 3em;
  } // end .modal-box
} // end @media screen and (min-width: $BREAKPOINT-DESKTOP)
