@import "utils";

/*
╔═════════════════════════════════════════════╗
║                                             ║
║            Mobile first settings            ║
║                                             ║
╚═════════════════════════════════════════════╝*/

.hidden,
[hidden] {
  display: none;
}

.visibity-hidden {
  visibility: hidden;
}

.opacity-0 {
  opacity: 0;
}

#zooooooooooooom {
  position: fixed;
  bottom: 1em;
  right: 0;
  left: 0;
  margin: 0 auto;
  width: 100%;
  padding: 0 1em;
  z-index: 9;
}

.page-body {
  background-color: var(--app-color-darkgrey);
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: row-reverse;

  .modify-letter {
    flex-grow: 1;
    width: 100%;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    overflow: auto;
    align-items: flex-start;

    .letter-body {
      position: relative;
      margin: 0.5em 0;
      text-align: justify;
      width: 21cm;
      height: 29.7cm;
      max-width: 21cm;
      max-height: 29.7cm;
      overflow: auto;

      &:not(.preview) {
        background-color: var(--app-color-white);
      }

      &:not(.preview) {
        transform-origin: left top;
      }

      &:not(.preview)::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background-color: var(--app-color-white);
        height: 100%;
        width: 100%;
        box-shadow: 0 0 0.735em 0.525em rgba(0, 0, 0, 0.5);
        transform-origin: center;
        transition: 0.25s;
        animation: rotatePage 0.25s cubic-bezier(0.7, 1, 0.6, 0.5);
      }

      @keyframes rotatePage {
        0% {
          transform: rotate(-5deg);
        }

        100% {
          transform: rotate(0);
        }
      }

      #letter-zone {
        min-width: 100%;
        padding: 4em;
        position: relative;
        z-index: 1;
        gap: 2em;
        font-family: "Georgia";

        .date-du-jour {
          text-align: right;
          margin-bottom: 1rem;
        }

        p span {
          cursor: pointer;
          -webkit-appearance: button;
          appearance: button;
          background-color: var(--app-color-beige);
          border: 1px solid var(--app-color-grey);
          width: 100%;
          max-width: fit-content;
          padding: 0.125em;
          @include curved-corners;
          @include hover-hand;
        }

        .employer,
        .user-signature {
          text-align: right;
          width: 100%;
          position: relative;
        }

        .okay {
          background-color: transparent;
          border: none;
        }

        .container-paragraph:first-of-type {
          margin-top: 3ex;
        }

        .container-paragraph {
          margin-bottom: 3ex;

          .letter-content {
            margin: 1em 0;
            display: flex;
            flex-direction: column;
            gap: 2em 0;

            .container-paragraph {
              display: flex;
              flex-direction: column;
              gap: 2ex;
              position: relative;

              #tooltip {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                position: absolute;
                width: 100%;
                top: -2.8em;
                font-family: Georgia, "Times New Roman", Times, serif;
                #tooltip-content {
                  background-color: var(--app-color-black);
                  color: var(--app-color-white);
                  text-align: center;
                  padding: 0.6em;
                  z-index: 2;
                  border-radius: 1px;
                }
                #tooltip-arrow::after {
                  content: "";
                  position: absolute;
                  width: 14px;
                  height: 14px;
                  left: 50%;
                  transform: translate(-50%, -50%) rotate(45deg);
                  background-color: var(--app-color-black);
                  z-index: 1;
                }
              }

              textarea {
                height: 0.375em;
                resize: none;
                width: 100%;
                padding: 0.375em;
                font-size: 1em;
                font-family: "Georgia";
                overflow: hidden;
                background-color: transparent;
                border: none;

                &:focus,
                &:active {
                  outline: none;
                  border: none;
                }
                &.active-tooltip {
                  background-color: var(--app-color-beige);
                  border: 1px solid var(--app-color-grey);
                  border-radius: 0.4em;
                }
              }

              ::selection {
                background-color: var(--app-color-blackgrey);
                color: var(--app-color-white);
                border: none;
                padding: 0.125em;
              }
            }
          }
        }
      }

      .thanks-answer {
        display: grid;
        grid-template-rows: 1fr;
        height: 100%;
        position: relative;
        z-index: 1;

        span:first-of-type {
          padding: 2em 0;
          width: unset;
          min-width: unset;
          display: inline-flex;
          flex-direction: row;
          gap: 0.125em;
          justify-content: center;

          div {
            span {
              padding: 0;
              flex-direction: unset;
            }

            input {
              display: flex;
            }
          }
        }

        h4 {
          min-height: 20cm;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .letter-signature {
        cursor: pointer;
        position: relative;
        min-height: 150px;

        .user-signature {
          margin-top: 1em;
        }

        .img-signature {
          position: absolute;
          top: 0;
          right: -3em;
          scale: 0.7;

          p,
          div,
          svg {
            pointer-events: none;
          }

          svg {
            margin-top: 1.625rem;
            min-width: 100px;
            max-width: 100px;
          }
        }
      }
    }

    .finalize-and-modify {
      position: fixed;
      max-width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 1em;
      padding: 1em;
      bottom: 0;

      a {
        width: 100%;
      }

      button {
        max-height: 5.5ex;
        color: var(--app-color-blackgrey);
        border-width: 0.15em;
        border-style: solid;
        border-color: var(--app-color-darkgrey);
        background-color: var(--app-color-white);
        width: 100%;
      }
    }
  }
}

/* 769 px*/
@media screen and (min-width: 48.0625em) {
  .letter-body {
    margin: 1.2em auto;

    * {
      color: var(--app-color-black);
    }
  }
}

@media (min-width: 62.0625em) {
  .modify-letter {
    align-items: center;

    .finalize-and-modify {
      display: none !important;
    }
  }

  .letter-body {
    transform: unset;
  }
}

@media print {
  .side-panel,
  .app-menu {
    display: none !important;
  }

  @page {
    .modify-letter,
    .page-body {
      size: A4;
      margin: 0;
      overflow: hidden;
    }
  }
}
