@import "utils";

/*
╔═════════════════════════════════════════════╗
║                                             ║
║             Mobile first settings           ║
║                                             ║
╚═════════════════════════════════════════════╝*/

button.versatile-button {
  background-color: black;
  &:hover {
    background-color: grey;
  }
  span.content {
    color: white;
  }
} // end .versatile-button

/*
╔═════════════════════════════════════════════╗
║                                             ║
║           Tablet / Desktop settings         ║
║                                             ║
╚═════════════════════════════════════════════╝*/

@media screen and (min-width: map-get($SCREEN_WIDTH, "md")) {
} // end @media screen and (min-width: map-get($SCREEN_WIDTH, 'md'))
