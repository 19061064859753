@import "utils";

/*
╔═════════════════════════════════════════════╗
║                                             ║
║             Mobile first settings           ║
║                                             ║
╚═════════════════════════════════════════════╝*/

.fullpage {
  display: flex;
  flex-direction: column;

  .control-widget-captcha {
    position: absolute;
    bottom: 11em;
    left: 0;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .controls {
    height: 5em;
    display: grid;
    // flex-wrap: wrap;
    // flex-direction: column;
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 30%;
    position: absolute;
    left: 35%;
    bottom: 7em;
    .control-button {
      display: flex;
      justify-content: center;
      button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        gap: 0.25em;
        width: 200px;
        height: 6ex;
        border: 0.015em solid var(--app-color-blackgrey);
        border-width: 0.15em;
        border-style: solid;
        border-radius: 0.75em;
        line-height: 0;
        text-transform: uppercase;
        background-color: var(--app-color-blackgrey);
        box-shadow: 0 0.15em 0.2em 0.15em var(--app-color-darkgrey);

        span {
          color: var(--app-color-white);
          font-weight: bold;
        }

        // svg {
        //     height: 10px;
        // }

        &:hover {
          box-shadow: 0 0.25em var(--app-color-grey);
          background-color: var(--app-color-black);
          border-color: var(--app-color-black);

          span {
            color: var(--app-color-white);
          }

          // svg path {
          //     fill: var(--app-color-white) !important;
          // }
        }
      }
    }
  }

  .widecontent {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    #typewriter-zone {
      align-self: center;
      text-align: center;
      font-size: var(--app-font-size-title3);
    }
  }
}

/*
╔═════════════════════════════════════════════╗
║                                             ║
║           Tablet / Desktop settings         ║
║                                             ║
╚═════════════════════════════════════════════╝*/

@media screen and (min-width: map-get($SCREEN_WIDTH, "md")) {
} // end @media screen and (min-width: map-get($SCREEN_WIDTH, 'md'))
