// @import "globals";
@import "utils";
/*
  Pour le moment tout le style associé aux composants actifs pour l'url /Home est dans cet unique fichier.
*/

/* =============== mixins begin =================== */
@mixin desktop-content-items {
  .content-items {
    display: flex;
    flex-direction: row;
    align-items: center;
  } // end .content-items
} // end mixin desktop-content-items

@mixin mobile-content-items {
  .content-items {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
} // end mixiin moble-content-items

/* =============== mixins end =================== */

/*
╔═════════════════════════════════════════════╗
║                                             ║
║             Mobile first settings           ║
║                                             ║
╚═════════════════════════════════════════════╝*/

.home {
  display: flex;
  flex-direction: column;
  .recruitment-kit {
    position: relative;
    text-align: center;
    background-color: var(--app-color-blackgrey);
    color: var(--app-color-white);
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 1em;
    padding-top: 1em;
    .accordion-toggler {
      color: var(--app-color-white);
      font-size: 1.4em;
      .icon {
        color: inherit;
        // padding: 0% 2% 2%;
        font-size: 14px;
        padding: 2px;
        border: #d9d9d9 1px solid;
        border-radius: 2px;
        // padding: 0% 2% 2%;
        font-size: 14px;
        padding: 2px;
        border: #d9d9d9 1px solid;
        border-radius: 2px;
        &:hover {
          cursor: pointer;
        }
      }
    } // end .accordion-toggler
    button,
    input[type="file"] {
      background-color: var(--app-color-darkergrey);
      width: 25em;
      font-family: var(--app-font-sf-prodisplay-bold);
      width: 25em;
      font-family: var(--app-font-sf-prodisplay-bold);
    }
    button {
      width: 100%;
      position: absolute;
      bottom: 1.5em;
      left: 0;
      right: 0;
      margin: 0 auto;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      gap: 0.625em;
      svg {
        max-height: 15px;
      }
    }
    .closing-widget {
      position: static;
      display: flex; // mandatory to center icon (cross) in the circle
      justify-content: center;
      margin: 2em auto 0;
      background-color: white;
    }
    .new-letter-widget {
      position: static;
      background-color: white;
      margin: 2em auto;
      width: fit-content;
    }
    .form-recruitment {
      display: flex;
      flex-direction: column;
      gap: 2em 0em;
      // margin-bottom: 3em;
      .manage-covering-letter {
        margin-top: 1em;
        position: relative;
        text-align: center;
      }
      .my-letter,
      .manage-resume,
      .manage-recommendation-letter,
      .manage-contact-message {
        height: 20em;
        padding: 1em;
        background-repeat: no-repeat;
        background-position: center;
        background-color: var(--app-color-black);
        position: relative;
        @include curved-corners;
        &.my-letter {
          background-image: url("../assets/pics/pictos/picto-cover-letter.png");
        }
        &.manage-recommendation-letter {
          background-image: url("../assets/pics/__placeholders/150x200.png");
        }
        &.manage-resume {
          background-image: url("../assets/pics/__placeholders/150x200.png");
        }
        &.manage-contact-message {
          .buttons-row {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            position: absolute;
            bottom: 1.5em;
            width: 100%;
            button {
              position: relative;
              width: fit-content;
              bottom: 0;
              @include curved-corners;
            }
            .copy-button {
              background-color: var(--app-color-white);
              color: var(--app-color-black);
              &:hover {
                background-color: var(--app-color-grey10);
              }
            }
            .download-button {
              background-color: var(--app-color-white);
              color: var(--app-color-black);
              &[downloaded="true"] {
                background-color: var(--app-color-beige);
              }
              &:hover {
                background-color: var(--app-color-darkergrey);
              }
            }
            .message-button {
            }
          } // end .buttons-row
          .message-text {
            textarea {
              background-color: var(--app-color-blacky);
              color: var(--app-color-white);
              width: 100%;
              border: none;
              padding: 1em;
              resize: none;
              text-overflow: ellipsis;
            } // end textarea
          } // end .message-text
        } // .manage-contact-message
      } // end .manage-contact-message
    } // end .form-recruitment
    h2 {
      color: var(--app-color-white);
      font-family: var(--app-font-georgia);
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      font-family: var(--app-font-georgia);
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
    }
    .title-and-folding-toggler-widget {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    } // end .title-and-folding-toggler-widget
    .panel-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1em;
    } // end .title box
  } // end class recruitment
  .relevant-infos {
    margin: 1em 0;
    padding: 1em 0;
    .application-completion {
      background-color: var(--app-color-beige);
    } // end .application-completion
    .employment-assistance {
      @include mobile-content-items;
    } // end .employment-assistance
    h1.app-title {
      font-size: 2.25em;
    }
    h2.app-title {
      color: #000;
      font-family: var(--app-font-georgia);
      font-family: var(--app-font-georgia);
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      font-size: 1.5em;
    }
    .information-card {
      /* override default style */
      height: 271px;
      max-width: 95%;
      min-width: 95%;
      margin: 0.5em 16px;
      overflow: hidden;
      .description {
        font-size: 14px;
        color: #303030;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        max-height: calc(2 * 1.2em);
        line-height: 1.2em; /* Hauteur de ligne */
      } // end .description
      .subject {
        /* card title */
        color: var(--app-color-blackgrey);
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
        font-family: var(--app-font-sf-prodisplay-bold);
        font-size: 20px;
      } // end .subject
      .picture-box img {
      } // end .picture-box
    } // end .information-card
    .relevant-content {
      @include mobile-content-items;
    } // end relevant-content
    .title-box {
      text-align: center;
      margin-top: 2em;
      a {
        min-height: 80px;
        max-height: 80px;
        img,
        svg {
          min-height: 80px;
        }
      }
    } // end .title-box
  }
  // end class relevant-infos
} // end class home

/*
╔═════════════════════════════════════════════╗
║                                             ║
║        Intermediate screen settings         ║
║                                             ║
╚═════════════════════════════════════════════╝*/

@media screen and (min-width: map-get($SCREEN_WIDTH, "sm")) {
  .recruitment-kit {
    // end .recruitment-kit
  }
  .relevant-infos {
    // job information
    .information-card {
      height: 500px;
      max-width: 500px;
      padding: 16px 16px 24px 16px;
      margin: 0.5em 0;
    }

    h2 {
      font-style: 22px;
    }
  }
} // end @media screen and (min-width: map-get($SCREEN_WIDTH, 'sm')

/*
╔═════════════════════════════════════════════╗
║                                             ║
║           Tablet / Desktop settings         ║
║                                             ║
╚═════════════════════════════════════════════╝*/

@media screen and (min-width: map-get($SCREEN_WIDTH, "md")) {
  .home {
    flex-direction: row;
    .recruitment-kit {
      padding: 0px;
      width: 35%;
      .recruitment-kit-content {
        position: fixed;
        width: 35%;
        padding: 1em;
        .button.versatile-button {
          width: fit-content;
        }
        .accordion-toggler {
          font-size: 1.4em;
        }
        .form-recruitment {
          padding: 1em 1em;
          .my-letter,
          .manage-resume,
          .manage-recommendation-letter,
          .manage-contact-message {
            height: 20em;
          }
        }
      } // end .form-recruitment
    } // end .recruitment-kit
    .relevant-infos {
      width: 65%;
      margin: 0;
      padding: 0;
      margin-top: 0.5em;
      padding-top: 0.5em;
      h1 {
        /* requested settings */
        margin: 0.7em 10%;
        padding: 0;
        font-weight: 100;
        line-height: 44px;
      } // end h1.app-title
      h2.app-title {
        margin: 0;
      } // end h2.app-title
      h2 {
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
      }
      .information-card {
        height: 500px;
        max-width: 500px;
        padding: 16px 16px 24px 16px;
        margin: 0.5em 0;
        min-width: fit-content;
      }
      .description {
        line-clamp: 10 !important;
        -webkit-line-clamp: 10 !important;
        max-height: calc(10 * 1.2em) !important;
      } // end .description

      .title-box {
        text-align: center;
      } // .title-box
    } // end .relevant-info
  } // end .home
} // end @media screen and (min-width: map-get($SCREEN_WIDTH, 'md'))

@media screen and (min-width: map-get($SCREEN_WIDTH, "xl")) {
  .home {
    .recruitment-kit {
      /* min-width: 0;
      max-width: 500px; */
      width: 25%;
      // height: fit-content;
      margin: 0;
      .recruitment-kit-content {
        width: 25%;
      }
      .button.versatile-button {
        width: fit-content;
      }
    } // end .recruitment-kit
    .relevant-infos {
      width: 75%;
      // margin: 0.5em 0;
      // padding: 0.5em 0;
    }
  } // end .home
} // end @media screen and (min-width: map-get($SCREEN_WIDTH, 'xl')
