@import "_globals-bootstrap";

/*
╔═══════════════════════════════════════════════════╗
║       SCSS App Global Constants & Variables       ║
╚═══════════════════════════════════════════════════╝*/

$app-site-url: "www.victoria.com";
$app-pics: "assets/pics";
$app-pics-icons: "$app-pics/icons";
$app-pics-logos: "$app-pics/logos";
$app-pics-photos: "$app-pics/photos";

$APP_MAX_CONTENT_WIDTH: 2500px; // max screen width used by app, living extra space as empty.
$APP_SCREEN_WIDTH_DESKTOP: #{$BS_DESKTOP_BREAKPOINT};

/* ---------------- APP SETTINGS -- --------------- */

$APP_LINE_HEIGHTS: (tight, 1), (smaller, 1em), (small, 1.25em), (medium, 1.5em),
  (large, 1.75em), (larger, 2em), (x-large, 2.5em), (xx-large, 3em);

// Values to be used in classes setting width, h.
$DIMENSIONS_PX: auto, 25px, 30px, 40px, 50px, 75px, 100px, 120px, 150px, 200px,
  250px, 300px, 400px, 500px;

$DIMENSIONS_PERCENT: 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75,
  80, 85, 90, 95, 100;
