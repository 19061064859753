@charset "UTF-8";

@import "globals";
@import "fonts";
@import "import-px-files";
@import "import-app-files";
// @import "../scss/fonts.scss";

/*
╔═════════════════════════════════════════════╗
║                SCSS Variables               ║
╚═════════════════════════════════════════════╝*/

$app-site-url: "hello-asso.fr";
$app-pics: "pics";
$app-pics-icons: "$app-pics/icons";
$app-pics-logos: "$app-pics/logos";
$app-pics-photos: "$app-pics/photos";

/* Reusable classes generation is based on SCSS custom global constants */

/* Title specifications h1 to h6 */
@mixin h-app-title($color, $font-size, $margin-bottom) {
  font-size: $font-size;
  font-family: var(--app-font-sf-prodisplay-regular);
  margin-bottom: $margin-bottom;
  padding: 10px 16px;
  // text-align: center;
}

@mixin absolute-center {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

@mixin dotted-thin-border() {
}

@mixin center-horizontally {
  margin-left: auto;
  margin-right: auto;
}

@mixin smooth-thin-border($color: "black", $type: "solid") {
  // border: #{$type} 1px #{$color});
}

/* Setting Bootstrap breakpoint aliases variables */
:root {
  @each $alias, $screen-width in $BS_BREAKPOINTS_ALIASES {
    --app-bootstrap-screen-#{$alias}: #{$screen-width};
  }

  --app-screen-width-desktop: #{$APP_SCREEN_WIDTH_DESKTOP};
  --app-max-content-width: #{$APP_MAX_CONTENT_WIDTH};
}

/* Responsive title font sizes (unelegant duplication but I couldn't find another way) */
@each $screen-width, $title-level, $font-size in $APP_RESPONSIVE_TITLE_FONTSIZES
{
  @media screen and (max-width: $screen-width) {
    :root {
      --app-font-size-title#{$title-level}: #{$font-size};
    }

    .app-font-size-title#{$title-level} {
      font-size: #{$font-size};
      font-family: var(--app-font-sf-prodisplay-bold);
    }

    h#{$title-level}.app-title {
      @include h-app-title(var(--app-color-default), #{$font-size}, 0);

      //margin-bottom: 2ex; /* Valid unit (it's not a typo!) */
      @media (min-width: 992px) {
        margin: 0 auto;
        width: 90%;
        padding: 10px 0;
      }
    }
  }

  // end @media screen
}

// end @each

/* Generate a set of incremental paddings */
@for $i from 1 through 4 {
  $percentage: $i * 5;

  // --app-padding-x-#{$percentage}: #{$percentage}%;
  // .app-padding-x-#{$percentage} {
  //   padding-left: $percentage * 1%;
  //   padding-right: $percentage * 1%;
  // }
}

// end @for

/*
╔═════════════════════════════════════════════╗
║             App common classes              ║
╚═════════════════════════════════════════════╝*/

/*
╔═════════════════════════════════════════════╗
║                                             ║
║             Mobile first settings           ║
║                                             ║
╚═════════════════════════════════════════════╝*/

/* --------- mixins BEGIN------------ */

@mixin curved-corners {
  // border-radius: .375em;
  border-radius: 0.4em;
}

@mixin form-button {
  background-color: var(--app-color-white);
  color: var(--app-color-default);
  @include linebreak;
  padding: 0.75em;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  width: 100%;
}

@mixin hover-hand {
  cursor: pointer;
}

@mixin linebreak {
  margin-bottom: 1em;
}

@mixin piled-and-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@mixin uppercase {
  text-transform: uppercase;
}

/* --------- mixins END------------ */
