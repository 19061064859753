@import "utils";

/*
╔═════════════════════════════════════════════╗
║                                                                                         ║
║                           Mobile first settings                            ║
║                                                                                         ║
╚═════════════════════════════════════════════╝*/

.m_dnone,
.d_dnone {
  display: none;
}

.side-panel {
  overflow: hidden;
  position: fixed;
  background-color: var(--app-color-blackgrey);
  width: 100vw;
  //   min-height: 50vh;
  max-height: 100vh;
  bottom: 0;
  padding: 1.5em 1em;
  left: 0;
  z-index: 10;
  display: flex;
  flex-wrap: wrap;

  .app-font-size-title2 {
    font-size: 1.4em;
    text-align: center;
    margin-bottom: 0.2em;
  }

  .modify-letter-indications {
    display: block;
    margin: auto;
    text-align: center;
    padding: 3%;
    // background: radial-gradient(circle at 18.7% 37.8%, rgb(250, 250, 250) 0%, rgb(225, 234, 238) 90%)
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.15) 0%,
        rgba(0, 0, 0, 0.15) 100%
      ),
      radial-gradient(
          at top center,
          rgba(255, 255, 255, 0.4) 0%,
          rgba(0, 0, 0, 0.4) 120%
        )
        #989898;
    strong {
      color: white;
      font-weight: 800;
    }
    p {
      margin: 1%;
    }
  }

  .finalize-and-modify {
    align-self: flex-end;
    display: none;

    .download-pdf > span {
      font-family: var(--app-font-sf-prodisplay-regular);
      font-size: 16px;
      font-style: normal;
      font-weight: 590;
      line-height: normal;
    }

    .add-paragraph {
      display: none;
      max-width: fit-content;
      background: var(--app-color-blackgrey);
      border-color: var(--app-color-black);
      color: var(--app-color-white);
      align-self: flex-end;
    }
  }

  .close {
    padding: 0.0625em;
    clip-path: circle();
    border-radius: 50%;
    border: 0.1875em solid var(--app-color-blackgrey);
    border: var(--app-color-black);
    position: absolute;
    top: 0.5em;
    left: 0.5em;

    svg {
      height: 2em;
      margin-top: 0.2em;
    }
  }

  & ~ .modify-letter {
    overflow-y: hidden;
  }

  /* .app-font-size-title2 {
    font-size: 1.25em;
  } */

  .previous-button {
    svg {
      transform: rotateY(180deg);
    }
  }

  form {
    overflow: hidden;
    width: 100%;

    label,
    p,
    span {
      color: var(--app-color-white);
    }

    > div > div,
    .usr-address,
    .empl-address,
    .empl-identity,
    .empl-enterprise {
      display: flex;
      flex-direction: column;
      gap: 0 1em;

      input:not(input[type="radio"]) {
        background-color: var(--app-color-beige);
        border: 0px;
        height: 3.5em;
      }
      input[type="radio"] {
        margin-right: 0.2em;
      }
      label,
      input:not(input[type="radio"]) {
        width: 100%;
        padding: 0.2em 1em;
        @include curved-corners;

        &:focus {
          outline: none;
        }

        &.valid {
          border: 0.1875em solid green;
        }

        &.invalid {
          border: 0.1875em solid red;
        }
      }
    }

    .selection {
      display: inline-flex;
      color: var(--app-color-black);

      font-style: normal;
      margin: 1.265em;
      width: fit-content;
      justify-content: center;
      align-items: center;
      flex: 1 1;
    }

    textarea {
      height: 0.3125em;
      font-family: Georgia, serif;
      max-height: 45vh;
      resize: none;
      width: 100%;
      padding: 1em;
      font-size: 1em;
      margin-top: 1em;
      @include curved-corners;

      &.text-zone {
        min-width: 100%;
        min-height: 150px;
      }
    }

    .usr-gender {
      margin-bottom: 1.5em;
    }
    .usr-gender,
    .empl-gender {
      > div {
        display: grid;
      }
    }
  }

  .usr-date {
    display: flex;
    flex-direction: column;
    gap: 0 1em;

    p {
      margin-bottom: 1ex;
    }

    label,
    input:not(input[type="radio"]) {
      width: 100%;
      height: 6ex;
      padding: 0 1em;

      @media (min-width: 62em) {
        padding: 0;

        &[name] {
          padding: 0.125em;
          background-color: var(--app-color-beige);
        }
      }

      @include curved-corners;
    }

    label {
      color: var(--app-color-black);

      @media (min-width: 62.0625em) {
        color: var(--app-color-white);

        p {
          color: var(--app-color-white);
        }
      }
    }
  }

  .handwritten-signature {
    width: 100%;
    min-height: 8ex;
    position: relative;
    margin-top: 3em;

    @media (min-width: 62.0625em) {
      p {
        color: var(--app-color-white);
      }
    }

    .signature-zone {
      width: 225px;
      min-height: 150px;
      box-shadow: 1px 1px 0.25em 0.0625em rgba(0, 0, 0, 0.5);
      position: relative;
      margin: 0 auto;
      background-color: var(--app-color-beige);

      svg {
        position: absolute;
        width: 100%;
        margin: auto 0;
        top: 0;
        bottom: 0;
        padding: 1em;
        z-index: 0;
      }

      @media screen and (min-width: 62.0625em) {
        box-shadow: none;
      }

      canvas {
        width: 225px;
        height: 150px;
        position: relative;
        z-index: 1;
      }
    }

    .indication-magnet {
      position: absolute;
      top: 0;
      left: 0;
    }
    .control-widget {
      grid-template-columns: repeat(1, auto);
    }
  }

  .control-widget,
  .form-widget-steps {
    display: grid;
    flex-grow: 1;
    grid-template-rows: 2fr;
    align-items: center;
    gap: 1em;
    width: 100%;
    margin: 2em 0 0 0;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(2, 1fr);

    & a {
      display: block;
      width: 100%;
    }

    .dynamic-button {
      /* Style par défaut */
      border-radius: 4px;
      border: 1px solid #ccc;
      background: #f3f3f3;
    }

    .dynamic-button-filled {
      border-radius: 6px;
      border: 1px solid #000;
      // background: #1c1c1c;
      background: red;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
      color: #fff; /* Change la couleur du texte si nécessaire */
    }
    button {
      width: 100%;
      height: 5.5ex;
      color: var(--app-color-blackgrey);
      border-width: 0.15em;
      border-style: solid;
      // border-radius: 0.75em;
      padding: 0.8em;
      line-height: 0;
      border-color: var(--app-color-darkgrey);
      background-color: var(--app-color-white);
      display: inline-flex;
      gap: 0.3em;
      align-items: center;
      justify-content: center;
      @include hover-hand;
      @include curved-corners;

      svg {
        height: 65%;
      }

      &:disabled,
      &[disabled] {
        cursor: not-allowed;
        background-color: var(--app-color-default);
        color: white;
        opacity: 0.5;
      }
      &.previous-button {
        background: var(--app-color-black);
        color: var(--app-color-beige);
        svg path {
          fill: var(--app-color-darkgrey) !important;
        }
      }

      &.next-button {
        background: var(--app-color-black);
        color: var(--app-color-beige);
        svg path {
          fill: var(--app-color-darkgrey) !important;
        }
      }

      & span {
        color: inherit;
        line-height: 0;
      }

      &:not([disabled]):hover {
        box-shadow: 0 0.1em 0.15em var(--app-color-grey);
        color: var(--app-color-white);
        background-color: var(--app-color-black);
        border-color: var(--app-color-black);

        svg path {
          fill: var(--app-color-white) !important;
        }
      }
    }
  }
  .linkGoHomeMobile {
    display: flex;
    justify-content: center;
    margin-top: 1em;
    width: 100%;
    height: fit-content;
    a {
      color: var(--app-color-beige);
      font-size: 1em;
      height: fit-content;
      display: flex;
      align-items: center;
      gap: 2px;
      span {
        color: var(--app-color-beige);
        font-size: 1.2em;
      }
    }
    a:hover {
      color: var(--app-color-white);
      span:hover {
        color: var(--app-color-white);
      }
    }
  }
  .modify-letter-indications > p {
    margin: 10%;
    @include curved-corners;
  }
}

@media screen and (min-width: map-get($SCREEN_WIDTH, "md")) {
  .app-font-size-title2 {
    margin-bottom: 1em;
  }
  .app-font-size-title2:not(:first-of-type) {
    margin-top: 0.5em;
  }
  .usr-date {
    label,
    input:not(input[type="radio"]) {
      padding: 0.5em 1em;
    }
  }
  .control-widget,
  .form-widget-steps {
    margin-top: 5em;
  }
  .linkGoHomeDesktop {
    display: block;
    margin-top: 1em;
    width: 100%;
    height: fit-content;
    a {
      color: var(--app-color-beige);
      font-size: 1em;
      height: fit-content;
      display: flex !important;
      align-items: center;
      justify-content: center;
      align-self: center;
      gap: 2px;
      span {
        color: var(--app-color-beige);
        font-size: 1.2em;
      }
    }
    a:hover {
      color: var(--app-color-white);
      span:hover {
        color: var(--app-color-white);
      }
    }
  }
}
@media screen and (min-width: map-get($SCREEN_WIDTH, "lg")) {
  .side-panel {
    max-width: 30vw;
    min-height: 100vh;
    width: 30vw;
    flex-grow: 1;
    z-index: 0;
    padding-top: 12ex;

    form {
      margin-top: 1em;
    }
    .finalize-and-modify {
      display: flex;

      .add-paragraph {
        align-self: flex-end;
      }
    }

    & ~ .modify-letter {
      margin-left: 40vw !important;
    }

    .form-widget-steps {
      button {
        margin-bottom: 1em;
      }
    }
  }

  label {
    color: var(--app-color-white);

    p {
      color: var(--app-color-white);
    }
  }

  .finalize-and-modify {
    flex-direction: column;
  }

  .close {
    display: none;
  }

  .selection {
    color: var(--app-color-white);
  }
}
