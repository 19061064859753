@import "utils";

/*
╔═════════════════════════════════════════════╗
║                                             ║
║             Mobile first settings           ║
║                                             ║
╚═════════════════════════════════════════════╝*/

.employment-sites {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--app-color-default);
  width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-right: 1.5em;
  margin-left: 1.5em;

  .app-title {
    width: 100%;
    margin-top: 1.2em;
    margin-bottom: 1em;
    margin-left: 1.2em;
    color: var(--app-color-default);
  }

  label {
    color: var(--app-color-default);
    margin-bottom: 1em;
  }

  .sites-icons {
    display: grid;
    align-items: flex-start; // vertical align on top
    justify-content: space-between;
    grid-auto-flow: dense;
    grid-template-columns: repeat(2, 1fr);
    padding: 1em;
    width: 100%;
    min-width: 67vw;
    .site-icon-link {
      text-align: center;
    }
    .picture-link {
      width: 60px;
      margin: 0 auto;
      .picture-box img {
        border-radius: 100px;
        max-height: 60px;
        width: 60px;
        height: 60px;
        min-width: 60px;
      }
      .image-link {
        text-align: center;
        border-radius: 44px;
        border: 1px solid var(--Neutral-stroke-grey, #e8e8e8);
        background: #0a66c2;
        box-shadow: 0px 1px 2px 0px rgba(34, 34, 34, 0.1);
        a {
          display: inline-block;
          text-align: center;
        }
      }

      label {
        display: inline-flex;
        justify-content: center;
        width: 100%;
        color: var(--app-color-white);
        font-family: var(--app-font-inter-variable-font-regular);
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      &:not(:first-child) {
        label {
          margin: 0 auto;
        }
      }
    }
  } // end class picture-link
  .title-box {
    color: var(--app-color-white);
  }
} // end .employment-sites

/*
╔═════════════════════════════════════════════╗
║                                             ║
║        Intermediate screen settings         ║
║                                             ║
╚═════════════════════════════════════════════╝*/

@media screen and (min-width: 450px) {
  .employment-sites {
    margin: 0;
    padding: 0;
    .sites-icons {
      gap: 1em 0px;
    } // end .sites-icons
  } // end .employment-sites
} // end @media screen and (min-width: 450px)

/*
╔═════════════════════════════════════════════╗
║                                             ║
║        Intermediate screen settings         ║
║                                             ║
╚═════════════════════════════════════════════╝*/

@media screen and (min-width: map-get($SCREEN_WIDTH, "md")) {
  .home {
    .employment-sites {
      .sites-icons {
        grid-template-columns: repeat(5, 1fr);
        padding: 1em 3em 3em 5em;
      }
    } // end .employment-sites
  }
} // end @media screen and (min-width: map-get($SCREEN_WIDTH, 'sm')

/*
╔═════════════════════════════════════════════╗
║                                             ║
║           Tablet / Desktop settings         ║
║                                             ║
╚═════════════════════════════════════════════╝*/

@media screen and (min-width: map-get($SCREEN_WIDTH, "xl")) {
  .home {
    .employment-sites {
      .sites-icons {
        /* display: flex;
        flex-wrap: wrap;
        justify-content: flex-start; */
        grid-template-columns: repeat(5, 1fr);
      }
    } // end .employment-sites
  } // end .home
} // end @media screen and (min-width: map-get($SCREEN_WIDTH, 'md'))
