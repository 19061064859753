@charset "UTF-8";
/*
╔═════════════════════════════════════════════╗
║               App Font Variables            ║
╚═════════════════════════════════════════════╝*/
/*
  First value: Font directory
  Second value: Font name
  */
$dir-and-fonts:
  ('sf-pro-display-cufonfonts', 'SF-PRODISPLAY-BLACK-ITALIC', 'OTF'),
  ('sf-pro-display-cufonfonts', 'SF-PRODISPLAY-BOLD', 'OTF'),
  ('sf-pro-display-cufonfonts', 'SF-PRODISPLAY-HEAVY-ITALIC', 'OTF'),
  ('sf-pro-display-cufonfonts', 'SF-PRODISPLAY-LIGHT-ITALIC', 'OTF'),
  ('sf-pro-display-cufonfonts', 'SF-PRODISPLAY-MEDIUM', 'OTF'),
  ('sf-pro-display-cufonfonts', 'SF-PRODISPLAY-REGULAR', 'OTF'),
  ('sf-pro-display-cufonfonts', 'SF-PRODISPLAY-SEMI-BOLD-ITALIC', 'OTF'),
  ('sf-pro-display-cufonfonts', 'SF-PRODISPLAY-THIN-ITALIC', 'OTF'),
  ('sf-pro-display-cufonfonts', 'SF-PRODISPLAY-ULTRA-LIGHT-ITALIC', 'OTF'),
  ('Inter', 'INTER-VARIABLE-FONT-REGULAR', 'TTF'),
  ('Inter', 'INTER-VARIABLE-FONT-ITALIC', 'TTF'),
  ('georgia', 'GEORGIA', 'TTF'),
  ('georgia', 'GEORGIA-BOLD', 'TTF'),
  ('georgia', 'GEORGIA-ITALIC', 'TTF'),
  ('georgia', 'GEORGIA-BOLD-ITALIC', 'TTF'),
;

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }
  @return $string;
}

:root {
  @each $dir, $f, $ftype in $dir-and-fonts {
    --app-font-#{to_lower_case($f)}: '#{str-replace($f, '-', ' ')}';
    .app-font-#{$f} {
      font-family: var(--app-font-#{to_lower_case($f)});
    }
  } // end @each
  --app-font-default: 'SF-PRODISPLAY-REGULAR';
  --app-font-bold: 'SF-PRODISPLAY-BOLD';
  .app-font-default {
    font-family: var(--app-font-default);
  }
  .app-font-bold {
    font-family: var(--app-font-bold);
  }
}  // end :root

/*
  This font registering does NOT work if declared in :root
*/
@each $dir, $f, $ftype in $dir-and-fonts {
  // @debug '../assets/fonts/#{$dir}/#{$f}.#{$ftype}';
  @font-face {
    font-family: '#{str-replace($f, '-', ' ')}';
    src: url('../assets/fonts/#{$dir}/#{$f}.#{$ftype}');
  }
}

:root {
  -webkit-font-smooting: antialiasing;
  -moz-osx-font-smoothing: grayscale;
}
